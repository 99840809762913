import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import MuiAccordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import EditIcon from '@mui/icons-material/Edit'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import CodeIcon from '@mui/icons-material/Code'

import { portalSettingState } from 'state/portalSettingStates'
import {
  type CategoryRawData,
  type CategoriesRawData,
} from 'components/category/categoryTypes'
import useRoute from 'hooks/useNavigate'
import { userAccessState } from 'state/userStates'
import { IconButton } from '@mui/material'
import { Path } from '../commonConstants'
import { MainHeader, SubHeader } from 'components/StyledComponents'
import usePortalSetting from 'hooks/usePortalSetting'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import EmptyCategoryIcon from 'assets/icons/empty_category.svg'
import useMember from 'hooks/useMember'

type RenderCategory = (params: {
  category: CategoryRawData
  paths?: string[]
  level?: number
  isLeaf?: boolean
}) => React.ReactNode

const CategoryAccordion = styled(MuiAccordion)<{
  level: number
  leaf: string
}>`
  border: ${({ level, theme }) =>
    level % 2 === 0 ? 'none' : `1px solid ${theme.palette.divider}`};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background: ${({ level, theme }) =>
    level % 2 === 0
      ? theme.palette.info.light
      : theme.palette.background.paper};
  width: ${({ leaf, theme }) =>
    leaf ? `calc(50% - ${theme.spacing(0.5)})` : '100%'};

  &:before {
    display: 'none';
    background-color: transparent;
    top: 0;
  }
`

const SubCategoriesWrapper = styled(Box)<{ edge: string }>`
  display: flex;
  flex-direction: ${({ edge }) => (edge ? 'row' : 'column')};
  flex-wrap: ${({ edge }) => (edge ? 'wrap' : '')};
  gap: ${({ theme }) => theme.spacing(1)};
`

const CategorySummary = styled(AccordionSummary)`
  flex-direction: row-reverse;

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }

  & .MuiAccordionSummary-content {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`

const CategoryControlLabel = styled(Box)`
  width: 100%;
`

const CategoriesListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const portalSetting = useRecoilValue(portalSettingState)
  const { getLocalizedCategoryName } = usePortalSetting()
  const userAccess = useRecoilValue(userAccessState)
  const { data: categoriesData, isLoading } = useSWR<CategoriesRawData>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/categories`
      : null,
  )
  const [categoryExpandedPaths, setCategoryExpandedPaths] = useState<string[]>(
    [],
  )
  const { checkAccesses } = useMember()

  const renderCategory: RenderCategory = useCallback(
    ({ category, paths = [], level = 0, isLeaf = false }) => {
      const hasOnlyLeaves =
        !!category.subcategories &&
        !category.subcategories.find(
          (subcategory) => subcategory.subcategories?.length,
        )

      return (
        <CategoryAccordion
          key={category.id}
          disableGutters
          elevation={0}
          level={level}
          leaf={isLeaf ? 'leaf' : ''}
          expanded={categoryExpandedPaths.includes(category.id)}
          onChange={() => {
            setCategoryExpandedPaths([...paths, category.id])
          }}
        >
          <CategorySummary
            expandIcon={
              !!category.subcategories &&
              category.subcategories.length > 0 && (
                <ArrowForwardIosSharpIcon
                  sx={{ fontSize: '0.9rem' }}
                  color="primary"
                />
              )
            }
          >
            {category.subcategories && category.subcategories.length > 0 ? (
              getLocalizedCategoryName(category) ?? category.id
            ) : (
              <CategoryControlLabel>
                {getLocalizedCategoryName(category) ?? category.id}
              </CategoryControlLabel>
            )}
          </CategorySummary>
          {!!category.subcategories && category.subcategories.length > 0 && (
            <AccordionDetails>
              <SubCategoriesWrapper edge={hasOnlyLeaves ? 'edge' : ''}>
                {category.subcategories.map((subcategory) =>
                  renderCategory({
                    category: subcategory,
                    paths: [...paths, category.id],
                    level: level + 1,
                    isLeaf: hasOnlyLeaves,
                  }),
                )}
              </SubCategoriesWrapper>
            </AccordionDetails>
          )}
        </CategoryAccordion>
      )
    },
    [categoryExpandedPaths],
  )

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'edit_categories.header' })}
          </MainHeader>
        </Box>

        {categoriesData &&
          categoriesData?.categoryInfo?.categories.length > 0 &&
          checkAccesses({
            [PortalSection.CATEGORIES]: [FeatureAccess.WRITE],
          }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => {
                goTo(Path.CATEGORIES_EDIT)
              }}
              color="secondary"
            >
              {formatMessage({
                id: 'edit_categories.button.edit_categories',
              })}
            </Button>
          )}

        {userAccess?.userData.admin && (
          <IconButton
            size="small"
            color="secondary"
            onClick={() => {
              goTo(Path.CATEGORIES_EDITOR)
            }}
            aria-label="Code editor"
          >
            <CodeIcon />
          </IconButton>
        )}
      </Stack>

      {!isLoading &&
        !categoriesData?.categoryInfo?.categories?.length &&
        checkAccesses({
          [PortalSection.CATEGORIES]: [FeatureAccess.WRITE],
        }) && (
          <Stack
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            overflow={'auto'}
          >
            <Stack textAlign="center" spacing={2} alignItems="center">
              <EmptyCategoryIcon />
              <Box width="100%">
                <SubHeader>
                  {formatMessage({
                    id: 'edit_categories.label.start_creating_categories',
                  })}
                </SubHeader>
              </Box>
              <Box width="100%">
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    goTo(Path.CATEGORIES_EDIT)
                  }}
                  color="secondary"
                >
                  {formatMessage({
                    id: 'edit_categories.button.edit_categories',
                  })}
                </Button>
              </Box>
            </Stack>
          </Stack>
        )}

      <Box flexGrow={1} overflow={'auto'}>
        {isLoading && <CircularProgress />}

        {!isLoading && (
          <Stack spacing={1}>
            {categoriesData?.categoryInfo?.categories?.map((category) =>
              renderCategory({ category }),
            )}
          </Stack>
        )}
      </Box>
    </Stack>
  )
}

export default CategoriesListPage
