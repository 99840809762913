import { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import MuiAccordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import AccordionDetails from '@mui/material/AccordionDetails'
import CircularProgress from '@mui/material/CircularProgress'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'

import { type CategoryData } from 'components/category/categoryTypes'
import usePortalSetting from 'hooks/usePortalSetting'
import { isMobileViewState } from 'state/layoutStates'

type RenderCategory = (params: {
  category: CategoryData
  paths?: string[]
  level?: number
  isLeaf?: boolean
}) => React.ReactNode

type CategorySelectorProps = {
  selectedCategoryIds?: string
  categories: CategoryData[]
  isLoading: boolean
  onCategoryChange: (categoryName: string) => void
}

const CategoryAccordion = styled(MuiAccordion)<{
  level: number
  leaf: string
}>`
  border: ${({ level }) => (level % 2 === 0 ? 'none' : '1px solid')};
  border-color: ${({ theme }) => theme.palette.divider};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background: ${({ level, theme }) =>
    level % 2 === 0
      ? theme.palette.primary.light
      : theme.palette.background.paper};
  width: ${({ leaf, theme }) =>
    leaf ? `calc(50% - ${theme.spacing(0.5)})` : '100%'};

  &:before {
    display: 'none';
    background-color: transparent;
    top: 0;
  }
`

const SubCategoriesWrapper = styled(Box)<{ edge: string }>`
  display: flex;
  flex-direction: ${({ edge }) => (edge ? 'row' : 'column')};
  flex-wrap: ${({ edge }) => (edge ? 'wrap' : '')};
  gap: ${({ theme }) => theme.spacing(1)};
`

const CategorySummary = styled(AccordionSummary)`
  flex-direction: row-reverse;

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }

  & .MuiAccordionSummary-content {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`

const CategoryControlLabel = styled(FormControlLabel)`
  width: 100%;

  & .MuiRadio-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  & .MuiFormControlLabel-label {
    flex-grow: 1;
  }
`

const CategorySelector: React.FC<CategorySelectorProps> = ({
  selectedCategoryIds,
  categories,
  isLoading,
  onCategoryChange,
}) => {
  const [categoryExpandedPaths, setCategoryExpandedPaths] = useState<string[]>(
    [],
  )
  const { getLocalizedContent } = usePortalSetting()
  const isMobileView = useRecoilValue(isMobileViewState)

  useEffect(() => {
    if (selectedCategoryIds) {
      setCategoryExpandedPaths(selectedCategoryIds.split('|'))
    }
  }, [selectedCategoryIds])

  const handleCategoryChange = (ids: string): void => {
    onCategoryChange(ids)
  }

  const renderCategory: RenderCategory = useCallback(
    ({ category, paths = [], level = 0, isLeaf = false }) => {
      const value = [...paths, category.id].join('|')
      const hasOnlyLeaves =
        !!category.subcategories &&
        !category.subcategories.find(
          (subcategory) => subcategory.subcategories?.length,
        )

      return (
        <CategoryAccordion
          key={category.id}
          disableGutters
          elevation={0}
          level={level}
          leaf={isLeaf && !isMobileView ? 'leaf' : ''}
          expanded={categoryExpandedPaths.includes(category.id)}
          onChange={() => {
            setCategoryExpandedPaths([...paths, category.id])
          }}
        >
          <CategorySummary
            expandIcon={
              !!category.subcategories &&
              category.subcategories.length > 0 && (
                <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
              )
            }
          >
            {category.subcategories && category.subcategories.length > 0 ? (
              getLocalizedContent(category.names) ?? category.id
            ) : (
              <CategoryControlLabel
                labelPlacement="start"
                value={value}
                control={
                  <Radio
                    name={paths.join()}
                    checked={value === selectedCategoryIds}
                  />
                }
                onChange={(): void => {
                  handleCategoryChange(value)
                }}
                label={getLocalizedContent(category.names) ?? category.id}
              />
            )}
          </CategorySummary>
          {!!category.subcategories && category.subcategories.length > 0 && (
            <AccordionDetails>
              <SubCategoriesWrapper edge={hasOnlyLeaves ? 'edge' : ''}>
                {category.subcategories.map((subcategory) =>
                  renderCategory({
                    category: subcategory,
                    paths: [...paths, category.id],
                    level: level + 1,
                    isLeaf: hasOnlyLeaves,
                  }),
                )}
              </SubCategoriesWrapper>
            </AccordionDetails>
          )}
        </CategoryAccordion>
      )
    },
    [categoryExpandedPaths, selectedCategoryIds],
  )

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Stack spacing={1}>
      {categories?.map((category) => renderCategory({ category }))}
    </Stack>
  )
}

export default CategorySelector
