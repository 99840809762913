import { useCallback, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { format } from 'date-fns'

import {
  currentLocaleState,
  portalSettingState,
  publicPortalInfoState,
} from 'state/portalSettingStates'
import {
  DEFAULT_DATE_FORMAT,
  SUPPORT_REGIONS_PHONE_PREFIX_MAP,
} from '../commonConstants'
import { type LocalizedString } from 'types'
import {
  type LocalizedCategory,
  type CategoryRawData,
  type CategoryData,
} from 'components/category/categoryTypes'
import { findCategoryData, getCategoryTranslation } from 'utils/categoryUtils'

type UsePortalSettingReturn = {
  formatDate: (dateString: string, showOnlyDate?: boolean) => string
  formatPhoneNumber: (input: string) => string
  getLocalizedContent: (inputs?: LocalizedString[]) => string
  getLocalizedCategoryName: (categoryData: CategoryRawData) => string
  extractLocalizedCategoryNames: (inputCategory: LocalizedCategory) => string[]
  retrieveCategoryDataNames: (
    ids: string,
    caseInputs: CategoryData[],
  ) => string[]
  extractCategoryIds: (inputCategory: LocalizedCategory) => string
}

const usePortalSetting = (): UsePortalSettingReturn => {
  const portalSetting = useRecoilValue(portalSettingState)
  const publicPortalInfo = useRecoilValue(publicPortalInfoState)
  const currentLocale = useRecoilValue(currentLocaleState)

  const formatDate = useCallback(
    (dateString: string, showOnlyDate?: boolean): string => {
      if (!dateString) {
        return ''
      }

      const rule =
        portalSetting?.dateFormat ||
        publicPortalInfo?.dateFormat ||
        DEFAULT_DATE_FORMAT

      const date = new Date(dateString)
      return format(date, showOnlyDate ? rule.split(' ')[0] : rule)
    },
    [portalSetting, publicPortalInfo],
  )

  const getLocalizedContent = useCallback(
    (inputs?: LocalizedString[]): string => {
      if (!inputs) {
        return ''
      }

      const locale =
        currentLocale ||
        portalSetting?.defaultLanguage ||
        publicPortalInfo?.defaultLanguage

      const input = inputs.find((input) => input.language === locale)

      return input?.content || ''
    },

    [portalSetting, currentLocale, publicPortalInfo],
  )

  const formatPhoneNumber = useCallback(
    (phoneNumber: string): string => {
      const trimmedPhoneNumber = phoneNumber.replace(/\s+/g, '')

      if (trimmedPhoneNumber.startsWith('00')) {
        return trimmedPhoneNumber.replace(/^00/, '+')
      }

      if (
        !portalSetting?.mapConfiguration?.region ||
        trimmedPhoneNumber.startsWith('+')
      ) {
        return trimmedPhoneNumber
      }

      if (phoneNumber.startsWith('0')) {
        const prefix =
          SUPPORT_REGIONS_PHONE_PREFIX_MAP[
            portalSetting.mapConfiguration?.region
          ]
        return trimmedPhoneNumber.replace(/^0/, prefix)
      }

      return trimmedPhoneNumber
    },
    [portalSetting],
  )

  const getLocalizedCategoryName = useCallback(
    (categoryData: CategoryRawData): string => {
      if (!portalSetting) {
        return ''
      }

      return getCategoryTranslation(
        categoryData.translations,
        currentLocale,
        portalSetting.defaultLanguage,
      )
    },
    [currentLocale, portalSetting],
  )

  const extractLocalizedCategoryNames = (
    inputCategory: LocalizedCategory,
  ): string[] => {
    const labels: string[] = []

    const traverseCategory = (category: LocalizedCategory): void => {
      labels.push(getLocalizedContent(category.names))
      if (category.subcategory) {
        traverseCategory(category.subcategory)
      }
    }

    traverseCategory(inputCategory)

    return labels
  }

  const extractCategoryIds = (inputCategory: LocalizedCategory): string => {
    const ids: string[] = []

    const traverseCategory = (category: LocalizedCategory): void => {
      ids.push(category.key)
      if (category.subcategory) {
        traverseCategory(category.subcategory)
      }
    }

    traverseCategory(inputCategory)

    return ids.join('|')
  }

  const retrieveCategoryDataNames = (
    ids: string,
    caseInputs: CategoryData[],
  ): string[] => {
    const caseCategories = ids.split('|')
    const labels = []

    if (caseCategories && caseInputs) {
      for (const category of caseCategories) {
        const foundCategory = findCategoryData(category, caseInputs)
        if (foundCategory) {
          labels.push(getLocalizedContent(foundCategory.names))
        }
      }
    }

    return labels
  }

  const memoizedValue = useMemo(
    () => ({
      formatDate,
      getLocalizedContent,
      formatPhoneNumber,
      getLocalizedCategoryName,
      extractLocalizedCategoryNames,
      retrieveCategoryDataNames,
      extractCategoryIds,
    }),
    [formatDate],
  )

  return memoizedValue
}

export default usePortalSetting
