import { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import ResourceWrapper from 'components/resource/ResourceWrapper'
import ResourceGallery from 'components/resource/ResourceGallery'
import { type Resource } from 'types'
import { selectedResourceIndexState } from 'state/resourceStates'
import { getResourceFormat } from 'utils/fileUtils'
import { FileType } from '../../commonConstants'

type ImageListProps = {
  resources?: Resource[]
  max?: number
  size: number
  noWrap?: boolean
}

const CounterMask = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 22px;
`

const ResourceListWrapper = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(1)};
`

const DEFAULT_MAX = 5

const ResourceList: React.FC<ImageListProps> = ({
  resources,
  max = DEFAULT_MAX,
  size,
  noWrap,
}) => {
  const setSelectedResourceIndex = useSetRecoilState(selectedResourceIndexState)
  const [isResourceGalleryOpen, setIsResourceGalleryOpen] = useState(false)

  const handleResourceGalleryClose = (): void => {
    setIsResourceGalleryOpen(false)
  }

  const openResourceGallery = (index: number): void => {
    setIsResourceGalleryOpen(true)
    setSelectedResourceIndex(index)
  }

  const handleClickResource = (resource: Resource, index: number): void => {
    if (getResourceFormat(resource) === FileType.IMAGE) {
      openResourceGallery(index)
    } else {
      const link = document.createElement('a')
      link.download = resource.name
      link.href = resource.uri
      link.target = '_blank'
      link.setAttribute('download', resource.name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  return (
    <ResourceListWrapper direction={'row'} flexWrap={'wrap'}>
      {resources?.map((resource, index) =>
        index < max ? (
          <ResourceWrapper
            key={resource.uri}
            format={getResourceFormat(resource)}
            width={size}
            height={size}
            onClick={() => {
              handleClickResource(resource, index)
            }}
            url={resource.uri}
            imageSize="cover"
          >
            {resources?.length > max && index === max - 1 && (
              <CounterMask>+{resources.length - max}</CounterMask>
            )}
          </ResourceWrapper>
        ) : null,
      )}

      <ResourceGallery
        resources={resources ?? []}
        isOpen={isResourceGalleryOpen}
        onClose={handleResourceGalleryClose}
      />
    </ResourceListWrapper>
  )
}

export default ResourceList
