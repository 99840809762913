import { useCallback, useState } from 'react'
import { useParams } from 'react-router'
import { useIntl } from 'react-intl'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import ListItemIcon from '@mui/material/ListItemIcon'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import ResourceGallery from 'components/resource/ResourceGallery'
import { type Resource } from 'types'
import ResourceWrapper from 'components/resource/ResourceWrapper'
import { InfoText, SubHeader } from 'components/StyledComponents'
import FileEditForm from 'components/file/FileEditForm'
import { selectedResourceIndexState } from 'state/resourceStates'
import useApi from 'hooks/useApi'
import { portalSettingState } from 'state/portalSettingStates'
import CircularProgress from '@mui/material/CircularProgress'
import usePortalSetting from 'hooks/usePortalSetting'
import { getResourceFormat } from 'utils/fileUtils'
import { FILE_SOURCE_LABEL } from 'components/case/caseConstants'
import { shortenUuid } from 'utils/stringUtils'

type FileListProps = {
  files?: Resource[]
  isLoading: boolean
  disabled: boolean
  onSave: (newFile: Resource) => void
  onDelete: (targetFile: Resource) => void
  goToLog: (referenceId: string) => void
}

const FileWrapper = styled(Stack)`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(1)};
  width: 100%;
`

const DescriptionWrapper = styled(Box)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
`

const FileList: React.FC<FileListProps> = ({
  files,
  isLoading,
  disabled,
  onSave,
  onDelete,
  goToLog,
}) => {
  const { formatMessage } = useIntl()
  const { caseId } = useParams()
  const [isOpenForm, setIsOpenForm] = useState(false)
  const portalSetting = useRecoilValue(portalSettingState)
  const setSelectedResourceIndex = useSetRecoilState(selectedResourceIndexState)
  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false)
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)
  const [selectedResource, setSelectedResource] = useState<Resource | null>()
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const { sendDeleteRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const { formatDate } = usePortalSetting()

  const openImageGallery = (index: number): void => {
    setIsImageGalleryOpen(true)
    setSelectedResourceIndex(index)
  }

  const openCaseExtraImages = (index: number): void => {
    openImageGallery(index)
  }

  const handleImageGalleryClose = (): void => {
    setIsImageGalleryOpen(false)
  }

  const handleAddFile = (): void => {
    setIsOpenForm(true)
  }

  const handleCloseFileForm = (): void => {
    setIsOpenForm(false)
    setSelectedResource(null)
  }

  const handleSave = (newFile: Resource): void => {
    handleCloseFileForm()
    onSave(newFile)
  }

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    file: Resource,
  ): void => {
    setMenuEl(event.currentTarget)
    setSelectedResource(file)
  }

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const handleEditFile = (): void => {
    setIsOpenForm(true)
  }

  const handleDeleteFile = (): void => {
    handleCloseMenu()
    setIsConfirmDialogOpen(true)
  }

  const handleCloseConfirmDialog = (): void => {
    setIsConfirmDialogOpen(false)
  }

  const handleConfirmedDeleteFile = useCallback(async (): Promise<void> => {
    if (portalSetting && caseId && selectedResource) {
      try {
        await sendDeleteRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/cases/${caseId}/resources/${selectedResource.id}`,
        )

        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })
        onDelete(selectedResource)
      } catch (error) {
        console.error(error)
      } finally {
        handleCloseConfirmDialog()
      }
    }
  }, [portalSetting, caseId, selectedResource])

  return (
    <Stack height={'100%'} spacing={1}>
      {!isOpenForm && (
        <Stack direction={'row'}>
          <SubHeader>{formatMessage({ id: 'file_list.header' })}</SubHeader>

          {!disabled && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AttachFileIcon />}
              onClick={handleAddFile}
            >
              {formatMessage({
                id: 'file_list.button.add_file',
              })}
            </Button>
          )}
        </Stack>
      )}

      <Stack spacing={1} flexGrow={1} overflow="auto">
        {isLoading && <CircularProgress />}

        {!isOpenForm &&
          files?.map((caseFile, index) => (
            <FileWrapper key={caseFile.id} direction="row" spacing={2}>
              <ResourceWrapper
                size={80}
                url={caseFile.uri}
                format={getResourceFormat(caseFile)}
                onClick={() => {
                  openCaseExtraImages(index)
                }}
                imageSize="cover"
              />

              <Stack flexGrow={1}>
                <Stack
                  direction="row"
                  width={'100%'}
                  alignItems="center"
                  spacing={1}
                >
                  <Box flexGrow={1}>
                    <Typography>
                      {caseFile.author.firstName} {caseFile.author.lastName}
                    </Typography>
                  </Box>

                  <InfoText>{formatDate(caseFile.created)}</InfoText>

                  <Divider orientation="vertical" flexItem />

                  <InfoText
                    onClick={() => {
                      goToLog(caseFile.id)
                    }}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    ID {shortenUuid(caseFile.id)}
                  </InfoText>

                  <Divider orientation="vertical" flexItem />

                  <Chip
                    label={formatMessage({
                      id: FILE_SOURCE_LABEL[caseFile.source],
                    })}
                    size="small"
                  />

                  {!disabled && (
                    <IconButton
                      onClick={(event): void => {
                        handleOpenMenu(event, caseFile)
                      }}
                      size="small"
                      aria-label={formatMessage({
                        id: 'general.icon_button.see_more',
                      })}
                    >
                      <MoreVertIcon
                        sx={{
                          fontSize: 16,
                        }}
                      />
                    </IconButton>
                  )}
                </Stack>
                <DescriptionWrapper>{caseFile.description}</DescriptionWrapper>
              </Stack>
            </FileWrapper>
          ))}
      </Stack>

      {isOpenForm && (
        <FileEditForm
          selectedResource={selectedResource}
          onClose={handleCloseFileForm}
          onSave={handleSave}
        />
      )}

      <ResourceGallery
        resources={files ?? []}
        isOpen={isImageGalleryOpen}
        onClose={handleImageGalleryClose}
      />

      <Menu anchorEl={menuEl} open={openMenu} onClose={handleCloseMenu}>
        <MenuItem onClick={handleEditFile}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>
            {formatMessage({
              id: 'file_list.menu.edit',
            })}
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={handleDeleteFile}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>
            {formatMessage({
              id: 'file_list.menu.delete',
            })}
          </ListItemText>
        </MenuItem>
      </Menu>

      <Dialog open={isConfirmDialogOpen}>
        <DialogTitle>
          {formatMessage({ id: 'file_list.delete_confirm.title' })}
        </DialogTitle>
        <DialogContent>
          {formatMessage({ id: 'file_list.delete_confirm.content' })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} autoFocus>
            {formatMessage({
              id: 'file_list.delete_confirm.button.cancel',
            })}
          </Button>
          <Button
            onClick={() => {
              void handleConfirmedDeleteFile()
            }}
            variant="contained"
            color="error"
          >
            {formatMessage({
              id: 'file_list.delete_confirm.button.delete',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default FileList
