import { useState } from 'react'
import styled from '@mui/material/styles/styled'
import { TreeView as MuiTreeView } from '@mui/x-tree-view/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import { type Locale } from 'types'
import SubCategoryTree from 'components/category/SubCategoryTree'
import { type CategoryRawData } from 'components/category/categoryTypes'

type CategoryTreeProps = {
  categories: CategoryRawData[]
  onAddCategory: (paths: string[]) => void
  onEditCategory: (
    paths: string[],
    translations: Record<Locale, string>,
  ) => void
  onDeleteCategory: (paths: string[]) => void
  onMoveCategory: (paths: string[], index: number, toIndex: number) => void
}

const ExpandIcon = styled(ChevronRightIcon)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  border-radius: 50%;
`

const CollapseIcon = styled(ExpandMoreIcon)`
  background: ${({ theme }) => theme.palette.info.light};
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  border-radius: 50%;
`

const TreeView = styled(MuiTreeView)`
  &,
  .MuiTreeItem-root,
  .MuiCollapse-vertical {
    display: flex;
    flex-direction: column;
  }

  gap: 10px;

  > .MuiTreeItem-root {
    border: 1px solid;
    border-color: ${({ theme }) => theme.palette.divider};
    border-radius: 8px;
  }

  .MuiTreeItem-root {
    padding: 8px;
  }

  .MuiCollapse-root .MuiTreeItem-root {
    padding-left: 10px;
    border-left: 1px solid;
    border-left-color: ${({ theme }) => theme.palette.divider};
    border-bottom: 1px solid;
    border-bottom-color: ${({ theme }) => theme.palette.divider};
    border-bottom-left-radius: 8px;
    margin-top: -4px;
  }

  .MuiTreeItem-content .MuiTreeItem-iconContainer svg {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 24px;
  }

  .MuiCollapse-root {
    .MuiTreeItem-content {
      padding-top: 8px;
      padding-bottom: 4px;
    }
  }

  .MuiTreeItem-content {
    gap: 10px;
    padding-top: 8px;
    padding-bottom: 4px;

    &.Mui-selected,
    &.Mui-focused {
      background-color: transparent;
    }
  }

  .MuiTreeItem-root .MuiTreeItem-content.Mui-selected,
  .MuiTreeItem-root .MuiTreeItem-content.Mui-focused {
    background-color: transparent;
  }
`

const CategoryTree: React.FC<CategoryTreeProps> = ({
  categories,
  onAddCategory,
  onEditCategory,
  onDeleteCategory,
  onMoveCategory,
}) => {
  const [currentHoverItem, setCurrentHoverItem] = useState<string | null>(null)

  return (
    <TreeView
      defaultCollapseIcon={<CollapseIcon />}
      defaultExpandIcon={<ExpandIcon />}
    >
      <SubCategoryTree
        paths={[]}
        categories={categories}
        onAddCategory={onAddCategory}
        onEditCategory={onEditCategory}
        onDeleteCategory={onDeleteCategory}
        onMoveCategory={onMoveCategory}
        setCurrentHoverItem={setCurrentHoverItem}
        currentHoverItem={currentHoverItem}
      />
    </TreeView>
  )
}

export default CategoryTree
