import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { useIntl } from 'react-intl'
import { getAnalytics, logEvent } from 'firebase/analytics'
import useSWR, { mutate } from 'swr'
import { subDays, subMonths, subYears } from 'date-fns'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'

import useRoute from 'hooks/useNavigate'
import { type CaseInfo } from 'components/case/caseTypes'
import {
  casesDataState,
  currentCaseListViewState,
  offsetState,
  selectedCaseShareabilitiesState,
  selectedCasePeriodState,
  selectedCaseStatusesState,
} from 'state/caseListStates'
import CaseFilter from 'components/case/CaseFilter'
import { MAP_VIEW_MAX_MARKERS, Path } from '../commonConstants'
import {
  Period,
  CurrentCaseListView,
  CaseStatus,
  CASE_SHAREABILITIES_DEFAULT,
  CASE_STATUSES_DEFAULT,
  CASE_PERIODS_DEFAULT,
} from 'components/case/caseConstants'
import { portalSettingState } from 'state/portalSettingStates'
import CaseList from 'components/case/CaseList'
import CaseListMap from 'components/case/CaseListMap'
import { MainHeader, SubHeader } from 'components/StyledComponents'
import EmptyCaseIcon from 'assets/icons/empty_case.svg'
import EmptyResultIcon from 'assets/icons/empty_result.svg'
import { Typography } from '@mui/material'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'

const CaseListPage: React.FC = () => {
  const { goTo } = useRoute()
  const { formatMessage } = useIntl()
  const [selectedCaseShareabilities, setSelectedCaseShareabilities] =
    useRecoilState(selectedCaseShareabilitiesState)
  const [selectedCaseStatuses, setSelectedCaseStatuses] = useRecoilState(
    selectedCaseStatusesState,
  )
  const [selectedCasePeriod, setSelectedCasePeriod] = useRecoilState(
    selectedCasePeriodState,
  )
  const [casesData, setCasesData] = useRecoilState(casesDataState)
  const offset = useRecoilValue(offsetState)

  const currentCaseListView = useRecoilValue(currentCaseListViewState)

  const portalSetting = useRecoilValue(portalSettingState)
  const [caseListUrl, setCaseListUrl] = useState<string | null>(null)
  const { checkAccesses } = useMember()
  const analytics = getAnalytics()

  useEffect(() => {
    logEvent(analytics, 'web_case_list_page_view')
  }, [])

  useEffect(() => {
    if (portalSetting) {
      const baseUrl = `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
        portalSetting.id
      }/cases`
      const baseParams = []

      if (currentCaseListView === CurrentCaseListView.MAP) {
        baseParams.push(`recordsNum=${MAP_VIEW_MAX_MARKERS}`)
      } else {
        baseParams.push(`offset=${offset}`)
      }

      const shareabilities = selectedCaseShareabilities.map(
        (shareability) => `shareability=${shareability}`,
      )

      const statuses = selectedCaseStatuses.map((status) => `status=${status}`)

      const periods: string[] = []
      const currentDate = new Date()
      currentDate.setHours(23)
      currentDate.setMinutes(59)
      currentDate.setSeconds(59)
      if (selectedCasePeriod === Period.WEEK) {
        const oneWeekAgo = subDays(currentDate, 7)

        periods.push(`createdAfter=${oneWeekAgo.toISOString()}`)
        periods.push(`createdBefore=${currentDate.toISOString()}`)
      } else if (selectedCasePeriod === Period.MONTH) {
        const oneMonthAgo = subMonths(currentDate, 1)

        periods.push(`createdAfter=${oneMonthAgo.toISOString()}`)
        periods.push(`createdBefore=${currentDate.toISOString()}`)
      } else if (selectedCasePeriod === Period.YEAR) {
        const oneYearAgo = subYears(currentDate, 1)

        periods.push(`createdAfter=${oneYearAgo.toISOString()}`)
        periods.push(`createdBefore=${currentDate.toISOString()}`)
      }
      console.log(
        `${baseUrl}?${[
          ...baseParams,
          ...shareabilities,
          ...statuses,
          ...periods,
        ].join('&')}`,
      )
      setCaseListUrl(
        `${baseUrl}?${[
          ...baseParams,
          ...shareabilities,
          ...statuses,
          ...periods,
        ].join('&')}`,
      )
    }
  }, [
    portalSetting?.id,
    offset,
    selectedCaseShareabilities,
    selectedCaseStatuses,
    selectedCasePeriod,
    currentCaseListView,
  ])

  const {
    data: casesResponseData,
    isLoading,
    isValidating,
  } = useSWR<CaseInfo[]>(caseListUrl, {
    revalidateOnFocus: true,
    refreshInterval: 60000,
  })

  useEffect(() => {
    if (casesResponseData && casesResponseData.length > 0) {
      setCasesData((prev) =>
        [...prev, ...casesResponseData].reduce((acc: CaseInfo[], caseInfo) => {
          const existingCaseIndex = acc.findIndex(
            (item) => item.id === caseInfo.id,
          )

          if (existingCaseIndex > -1) {
            acc[existingCaseIndex] = caseInfo
          } else {
            acc.push(caseInfo)
          }
          return acc
        }, []),
      )
    }

    if (casesResponseData && offset === 0) {
      setCasesData([...casesResponseData])
    }
  }, [casesResponseData, offset])

  const handleAddCase = (): void => {
    goTo(Path.CASES_ADD)
  }

  const handleRefresh = (): void => {
    if (caseListUrl) {
      void mutate(caseListUrl)
    }
  }

  const handleCaseClick = useCallback(
    (data: CaseInfo): void => {
      if (portalSetting) {
        void mutate(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/cases/${data.id}`,
          data,
          false,
        )
      }

      goTo(`${Path.CASES_LIST}/${data.id}`)
    },
    [portalSetting],
  )

  const isDefaultFiltersValue = useMemo((): boolean => {
    return (
      selectedCasePeriod === Period.MONTH &&
      selectedCaseStatuses.join('|') ===
        [CaseStatus.IN_PROGRESS, CaseStatus.NEW].join('|')
    )
  }, [selectedCaseStatuses, selectedCasePeriod])

  const handleResetFilters = (): void => {
    setSelectedCaseShareabilities(CASE_SHAREABILITIES_DEFAULT)
    setSelectedCaseStatuses(CASE_STATUSES_DEFAULT)
    setSelectedCasePeriod(CASE_PERIODS_DEFAULT)
  }

  return (
    <Stack height={'100%'}>
      <Grid container>
        <Grid item flexGrow={1} alignSelf={'center'}>
          <MainHeader>{formatMessage({ id: 'case_list.header' })}</MainHeader>
        </Grid>
        <Grid item textAlign={'right'}>
          <Stack direction={'row'} spacing={2}>
            <Box alignSelf={'center'}>
              {checkAccesses({
                [PortalSection.CASES]: [FeatureAccess.READ],
              }) && (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  size="small"
                  onClick={handleAddCase}
                  data-testid="add-case-button"
                  color="secondary"
                >
                  {formatMessage({ id: 'case_list.button.create_report' })}
                </Button>
              )}
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <CaseFilter />

      {!casesData?.length &&
        !isLoading &&
        isDefaultFiltersValue &&
        checkAccesses({
          [PortalSection.CASES]: [FeatureAccess.READ],
        }) && (
          <Stack
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Stack textAlign="center" spacing={2} alignItems="center">
              <EmptyCaseIcon />
              <Box width="100%">
                <SubHeader>
                  {formatMessage({
                    id: 'case_list.label.no_cases',
                  })}
                </SubHeader>
              </Box>
              <Box width="100%">
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  size="small"
                  onClick={handleAddCase}
                  data-testid="add-empty-case-button"
                  color="secondary"
                >
                  {formatMessage({ id: 'case_list.button.create_report' })}
                </Button>
              </Box>
            </Stack>
          </Stack>
        )}

      {!casesData?.length && !isLoading && !isDefaultFiltersValue && (
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Stack textAlign="center" spacing={2} alignItems="center">
            <EmptyResultIcon />
            <Box width="100%">
              <SubHeader>
                {formatMessage({
                  id: 'case_list_map.label.case_empty',
                })}
              </SubHeader>
              <Typography variant="body2">
                {formatMessage({
                  id: 'case_list.label.clear_filtering',
                })}
              </Typography>
            </Box>
            <Box width="100%">
              <Button
                variant="outlined"
                size="small"
                onClick={handleResetFilters}
                data-testid="reset-filters-button"
              >
                {formatMessage({ id: 'case_list.button.reset_filters' })}
              </Button>
            </Box>
          </Stack>
        </Stack>
      )}

      <Box flexGrow={1} overflow="hidden">
        {!casesData?.length && isLoading && <CircularProgress />}

        {currentCaseListView === CurrentCaseListView.LIST &&
          !!casesData?.length && (
            <CaseList isLoading={isLoading} onCaseClick={handleCaseClick} />
          )}

        {currentCaseListView === CurrentCaseListView.MAP &&
          !!casesData?.length &&
          portalSetting && (
            <CaseListMap
              caseListWidth={400}
              isLoading={isLoading || isValidating}
              onRefresh={handleRefresh}
              onCaseClick={handleCaseClick}
              language={portalSetting.defaultLanguage.toLowerCase()}
              region={portalSetting.mapConfiguration?.region}
              zoom={portalSetting.mapConfiguration?.zoomLevel}
              center={portalSetting.mapConfiguration?.centre}
            />
          )}
      </Box>
    </Stack>
  )
}

export default CaseListPage
