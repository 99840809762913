import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CommentIcon from '@mui/icons-material/Comment'
import Button from '@mui/material/Button'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import CheckIcon from '@mui/icons-material/Check'
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled'

import { type CaseComment } from 'types'
import CommentInput from 'components/comment/CommentInput'
import {
  COMMENT_ATTACHMENTS_MAX_COUNT,
  COMMENT_MAX_LENGTH,
} from '../../commonConstants'
import { FILE_INPUT_ACCEPT_TYPE } from 'utils/fileConstants'
import { SmallInfoText } from 'components/StyledComponents'
import usePortalSetting from 'hooks/usePortalSetting'
import ResourceList from 'components/resource/ResourceList'
import UserAvatar from 'components/user/UserAvatar'
import { shortenUuid } from 'utils/stringUtils'
import { Divider } from '@mui/material'

type CommentListProps = {
  comments?: CaseComment[]
  isShared?: boolean
  isResolved?: boolean
  isLimited?: boolean
  disabled: boolean
  onResolve?: () => Promise<void>
  onSend?: (newComment: CaseComment) => Promise<void>
  goToLog?: (referenceId: string) => void
}

const Wrapper = styled(Stack)`
  width: 100%;
`

const SenderName = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`

const ResolvedTextWrapper = styled(Stack)`
  color: ${({ theme }) => theme.palette.primary.light};
`

const CommentsWrapper = styled(Stack)`
  width: 100%;
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  white-space: pre-line;

  .REPORTER {
    margin-right: 50px;
    background: ${({ theme }) => theme.palette.info.light};
  }

  .MEMBER {
    margin-left: 50px;
    background: ${({ theme }) => theme.palette.info.dark};
  }
`

const CommentWrapper = styled(Stack)<{ resolved: string }>`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background: ${({ resolved }) => (resolved ? '' : '#FFF0F3')};
  margin-left: 50px;
`

const EmptyCommentWrapper = styled(Stack)`
  color: #bbc6f1;
  width: 100%;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  padding: ${({ theme }) => theme.spacing(2)};
`

const CommentList: React.FC<CommentListProps> = ({
  comments,
  isShared = false,
  isResolved = false,
  isLimited = false,
  disabled = false,
  onResolve,
  onSend,
  goToLog,
}) => {
  const { formatMessage } = useIntl()
  const { formatDate } = usePortalSetting()

  return (
    <Wrapper data-testid="comment-list">
      <Stack spacing={2}>
        {!!comments?.length && (
          <CommentsWrapper spacing={1}>
            {comments?.map((comment) => (
              <CommentWrapper
                key={comment.id}
                resolved={comment.resolved ? 'resolved' : ''}
                className={comment.sender.type}
                paddingX={2}
                paddingY={1.5}
              >
                <Stack direction="row" spacing={1}>
                  <UserAvatar
                    avatarUrl={comment.sender.avatarUrl ?? ''}
                    size={30}
                    name={comment.sender.displayName}
                  />
                  <Stack>
                    <SenderName>
                      {comment.sender.displayName}
                      {comment.sender.type === 'REPORTER' &&
                        ` (${formatMessage({
                          id: 'comment_list.label.reporter',
                        })})`}
                      {comment.sender.type === 'MEMBER' &&
                        ` (${formatMessage({
                          id: 'comment_list.label.portal_member',
                        })})`}
                    </SenderName>
                    <Stack direction="row" spacing={1}>
                      <SmallInfoText>
                        {formatDate(comment.created)}
                      </SmallInfoText>

                      {goToLog && (
                        <>
                          <Divider orientation="vertical" flexItem />
                          <SmallInfoText
                            onClick={() => {
                              goToLog(comment.id)
                            }}
                            sx={{
                              cursor: 'pointer',
                            }}
                          >
                            ID {shortenUuid(comment.id)}
                          </SmallInfoText>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </Stack>

                <Stack marginTop={1} spacing={1}>
                  <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                    {comment.content}
                  </Typography>
                  <ResourceList
                    resources={comment.resources}
                    size={117}
                    max={2}
                  />
                </Stack>
              </CommentWrapper>
            ))}
          </CommentsWrapper>
        )}

        {!comments?.length && (
          <EmptyCommentWrapper>
            <CommentIcon fontSize="large" />
            {formatMessage({ id: 'comment_list.text.no_comments' })}
          </EmptyCommentWrapper>
        )}

        {!disabled && (
          <Stack
            direction="row"
            paddingY={1.5}
            alignItems="center"
            alignSelf="flex-end"
            width="100%"
            justifyContent="end"
          >
            <Stack flexGrow={1}>
              {isShared && (
                <Stack direction="row" spacing={1}>
                  <PublicOutlinedIcon color="error" fontSize="small" />
                  <Typography variant="body2" color="error">
                    {formatMessage({ id: 'comment_list.text.case_shared' })}
                  </Typography>
                </Stack>
              )}

              {isLimited && (
                <Stack direction="row" spacing={1}>
                  <CommentsDisabledIcon color="error" fontSize="small" />
                  <Typography variant="body2" color="error">
                    {formatMessage({ id: 'comment_list.text.comment_limited' })}
                  </Typography>
                </Stack>
              )}
            </Stack>

            {!isResolved && onResolve && (
              <Button
                size="small"
                variant="outlined"
                startIcon={<CheckIcon />}
                onClick={(): void => {
                  if (onResolve) {
                    void onResolve()
                  }
                }}
              >
                {formatMessage({
                  id: 'case_detail.button.resolve_comment',
                })}
              </Button>
            )}

            {isResolved && (
              <ResolvedTextWrapper direction="row" spacing={1}>
                <CheckIcon fontSize="small" />
                <Typography variant="body2">
                  {formatMessage({
                    id: 'comment_list.label.resolved',
                  })}
                </Typography>
              </ResolvedTextWrapper>
            )}
          </Stack>
        )}
      </Stack>

      {!disabled && (
        <CommentInput
          formName="comments"
          maxLength={COMMENT_MAX_LENGTH}
          accept={[
            FILE_INPUT_ACCEPT_TYPE.IMAGE,
            FILE_INPUT_ACCEPT_TYPE.DOCUMENT,
            FILE_INPUT_ACCEPT_TYPE.EMAIL,
          ]}
          limit={COMMENT_ATTACHMENTS_MAX_COUNT}
          placeholder={formatMessage({ id: 'comment_list.label.add_comment' })}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSend={onSend}
        />
      )}
    </Wrapper>
  )
}

export default CommentList
