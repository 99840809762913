import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, Controller } from 'react-hook-form'
import dayjs from 'dayjs'
import styled from '@mui/material/styles/styled'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import CircularProgress from '@mui/material/CircularProgress'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import CloseIcon from '@mui/icons-material/Close'

import {
  portalSettingState,
  sortedSupportLanguagesSelector,
} from 'state/portalSettingStates'
import MultiLineInput from 'components/form/MultiLineInput'
import { ALERT_TEXT_MAX_LENGTH } from 'commonConstants'
import useApi from 'hooks/useApi'
import usePortalSetting from 'hooks/usePortalSetting'
import LogoWrapper from 'components/LogoWrapper'
import DateInput from 'components/form/DateInput'
import { hasUnsavedChangesState } from 'state/formStates'
import { convertLocalizedStringToData } from 'utils/stringUtils'
import {
  type Alert,
  type AlertItemInfo,
  type AlertFormData,
} from 'components/alert/alertTypes'

type AlertEditDialogProps = {
  alert: Alert | null
  isOpen: boolean
  isSaving: boolean
  onSaveAsActive: (data: AlertFormData) => void
  onSaveAsDraft: (data: AlertFormData) => void
  onClose: () => void
}

const InfoWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`

const AlertEditDialog: React.FC<AlertEditDialogProps> = ({
  alert,
  isOpen,
  isSaving,
  onSaveAsActive,
  onSaveAsDraft,
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const { sendPostRequest } = useApi()
  const portalSetting = useRecoilValue(portalSettingState)
  const { getLocalizedContent } = usePortalSetting()
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const [items, setItems] = useState<AlertItemInfo[]>([])
  const [isLoadingItems, setIsLoadingItems] = useState(false)
  const [isExpiryDisabled, setIsExpiryDisabled] = useState(true)
  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid, isDirty, touchedFields },
    reset,
  } = useForm<AlertFormData>({
    mode: 'onTouched',
  })

  const getItems = async (): Promise<void> => {
    setIsLoadingItems(true)
    const response = await sendPostRequest(
      `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
        portalSetting?.id
      }/alerts:listAssignableItems`,
    )
    const itemsData = await response.json()
    setItems(itemsData)
    setIsLoadingItems(false)
  }

  useEffect(() => {
    if (isOpen) {
      reset()
      void getItems()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && alert) {
      setValue('translations', convertLocalizedStringToData(alert.texts))

      if (alert.expiry) {
        setValue('expiry', dayjs(alert.expiry))
        setIsExpiryDisabled(false)
      }
    }
  }, [alert, isOpen])

  useEffect(() => {
    if (isDirty && Object.keys(touchedFields).length > 0) {
      setHasUnsavedChanges(true)
    }
  }, [isDirty, JSON.stringify(touchedFields)])

  const handleSaveAsActive = useCallback((): void => {
    if (alert) {
      const formData = getValues()
      setHasUnsavedChanges(false)
      onSaveAsActive({
        ...formData,
        id: alert.id,
      })
    }
  }, [alert])

  const handleSaveAsDraft = (): void => {
    const formData = getValues()
    setHasUnsavedChanges(false)
    onSaveAsDraft(formData)
  }

  const getErrorMessage = useCallback(
    (language: string) => {
      if (errors?.translations?.[language]?.type === 'maxLength') {
        return formatMessage(
          {
            id: 'general.error.max_length',
          },
          { max: ALERT_TEXT_MAX_LENGTH },
        )
      }

      if (errors?.translations?.[language]?.type === 'required') {
        return formatMessage({
          id: 'general.error.required',
        })
      }

      return ''
    },
    [errors],
  )

  const handleClose = (reason: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }

  const handleToggleExpirationDate = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setValue('expiry', null)
    setIsExpiryDisabled(!event.target.checked)
  }

  const disableSubmit = useMemo(
    () =>
      !isValid || Object.keys(errors).length > 0 || isSaving || isLoadingItems,
    [isValid, errors, isSaving, isLoadingItems],
  )

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        handleClose(reason)
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {formatMessage({
          id: 'alert_edit.header',
        })}
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        aria-label={formatMessage({
          id: 'general.button.close',
        })}
        color="inherit"
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {isLoadingItems && <CircularProgress />}

        {!isLoadingItems && (
          <Stack minWidth="500px" spacing={1}>
            <FormControl fullWidth error={!!errors.itemId}>
              <InputLabel id="item-label" size="small" required>
                {formatMessage({
                  id: 'alert_edit.label.item',
                })}
              </InputLabel>
              <Controller
                name="itemId"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={alert?.item.id || ''}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="item-label"
                    label={formatMessage({
                      id: 'alert_edit.label.item',
                    })}
                    size="small"
                    fullWidth
                    variant="outlined"
                  >
                    {items.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <LogoWrapper
                            size={22}
                            url={item.logoUrl}
                            alt={getLocalizedContent(item.names)}
                          ></LogoWrapper>
                          <Typography>
                            {getLocalizedContent(item.names)}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            {supportLanguages.map((language) => (
              <FormControl
                key={language}
                error={!!errors?.translations?.[language]}
              >
                <Controller
                  name={`translations.${language}`}
                  control={control}
                  rules={{
                    maxLength: ALERT_TEXT_MAX_LENGTH,
                    required: true,
                  }}
                  defaultValue={''}
                  render={({ field }) => (
                    <MultiLineInput
                      {...field}
                      error={!!errors?.translations?.[language]}
                      maxLength={ALERT_TEXT_MAX_LENGTH}
                      rows={2}
                      variant="outlined"
                      fullWidth
                      startAdornment={language}
                      helpMessage={getErrorMessage(language)}
                    />
                  )}
                />
              </FormControl>
            ))}

            <FormControlLabel
              value="start"
              control={
                <Switch
                  checked={!isExpiryDisabled}
                  onChange={handleToggleExpirationDate}
                />
              }
              label={formatMessage({
                id: 'alert_edit.label.expiration_date_switch',
              })}
              labelPlacement="start"
            />

            <FormControl error={!!errors?.expiry}>
              <Controller
                name="expiry"
                control={control}
                rules={{
                  validate: (val): boolean | string => {
                    if (!val) {
                      return true
                    }

                    if (val.toDate() < new Date()) {
                      return 'validate'
                    }

                    return true
                  },
                }}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    disabled={isExpiryDisabled}
                    label={formatMessage({
                      id: 'alert_edit.label.expiration_date',
                    })}
                    allowOnlyFuture={true}
                    showTime={true}
                  />
                )}
              />
            </FormControl>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Stack width="100%" spacing={1}>
          <InfoWrapper>
            {formatMessage({
              id: 'alert_edit.activate_text',
            })}
          </InfoWrapper>

          <Button
            fullWidth
            onClick={handleSaveAsActive}
            variant="contained"
            disabled={disableSubmit}
            color="secondary"
          >
            {formatMessage({ id: 'alert_add.button.activate' })}
          </Button>
          <Button
            fullWidth
            onClick={handleSaveAsDraft}
            variant="outlined"
            disabled={disableSubmit}
          >
            {formatMessage({ id: 'alert_add.button.draft' })}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default AlertEditDialog
