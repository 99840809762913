import { atom } from 'recoil'
import {
  CASE_PERIODS_DEFAULT,
  CASE_SHAREABILITIES_DEFAULT,
  CASE_STATUSES_DEFAULT,
  type CaseStatus,
  CurrentCaseListView,
  type Period,
  type Shareability,
} from 'components/case/caseConstants'
import { type GridScrollParams } from '@mui/x-data-grid'
import { type CaseInfo } from 'components/case/caseTypes'

const selectedCaseShareabilitiesState = atom<Shareability[]>({
  key: 'selectedCaseShareabilitiesState',
  default: CASE_SHAREABILITIES_DEFAULT,
})

const selectedCaseStatusesState = atom<CaseStatus[]>({
  key: 'selectedCaseStatusesState',
  default: CASE_STATUSES_DEFAULT,
})

const selectedCasePeriodState = atom<Period>({
  key: 'selectedCasePeriodState',
  default: CASE_PERIODS_DEFAULT,
})

const casesDataState = atom<CaseInfo[]>({
  key: 'casesDataState',
  default: [],
})

const offsetState = atom<number>({
  key: 'offsetState',
  default: 0,
})

const lastScrollPositionState = atom<GridScrollParams | null>({
  key: 'lastScrollPositionState',
  default: null,
})

const currentCaseListViewState = atom<CurrentCaseListView>({
  key: 'currentCaseListViewState',
  default: CurrentCaseListView.LIST,
})

export {
  selectedCaseShareabilitiesState,
  selectedCaseStatusesState,
  selectedCasePeriodState,
  casesDataState,
  offsetState,
  lastScrollPositionState,
  currentCaseListViewState,
}
