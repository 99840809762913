import AssignmentIcon from '@mui/icons-material/Assignment'
import CheckIcon from '@mui/icons-material/Check'
import BuildIcon from '@mui/icons-material/Build'
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt'
import PublicIcon from '@mui/icons-material/Public'
import LockIcon from '@mui/icons-material/Lock'
import PublicOffIcon from '@mui/icons-material/PublicOff'

import {
  CaseStatus,
  Shareability,
  VirtualPortalCaseStatus,
} from './caseConstants'
import MailSendingIcon from 'assets/icons/mail_sending.svg'
import MailSentIcon from 'assets/icons/mail_sent.svg'
import MailUnsentIcon from 'assets/icons/mail_unsent.svg'

const getCaseStatusColor = (
  status: CaseStatus | VirtualPortalCaseStatus,
): string => {
  switch (status) {
    case CaseStatus.NEW:
      return '#6A79BB'
    case VirtualPortalCaseStatus.SENDING:
      return '#F3C44A'
    case VirtualPortalCaseStatus.SENT:
      return '#61C2B7'
    case VirtualPortalCaseStatus.UNSENT:
      return '#FF4869'
    case CaseStatus.IN_PROGRESS:
      return '#FFD74C'
    case CaseStatus.COMPLETED:
      return '#7ADCD0'
    case CaseStatus.CANCELLED:
      return '#E6E9EF'
    default:
      return 'white'
  }
}

const getCaseStatusIcon = (
  status: CaseStatus | VirtualPortalCaseStatus,
): typeof AssignmentIcon | typeof MailSentIcon | null => {
  switch (status) {
    case CaseStatus.NEW:
      return AssignmentIcon
    case VirtualPortalCaseStatus.SENDING:
      return MailSendingIcon
    case VirtualPortalCaseStatus.SENT:
      return MailSentIcon
    case VirtualPortalCaseStatus.UNSENT:
      return MailUnsentIcon
    case CaseStatus.IN_PROGRESS:
      return BuildIcon
    case CaseStatus.COMPLETED:
      return CheckIcon
    case CaseStatus.CANCELLED:
      return DoDisturbAltIcon
    default:
      return null
  }
}

const getShareabilityIcon = (
  shareability: Shareability,
): typeof AssignmentIcon | null => {
  switch (shareability) {
    case Shareability.SHAREABLE:
      return PublicOffIcon
    case Shareability.SHARED:
      return PublicIcon
    case Shareability.PRIVATE:
      return LockIcon
    default:
      return null
  }
}

export { getCaseStatusColor, getCaseStatusIcon, getShareabilityIcon }
