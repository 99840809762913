import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useSWR from 'swr'
import { useSnackbar } from 'notistack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress'
import LoadingButton from '@mui/lab/LoadingButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import useRoute from 'hooks/useNavigate'
import AddressInput from 'components/form/AddressInput'
import {
  type CategoriesRawData,
  type CategoryData,
} from 'components/category/categoryTypes'
import useApi from 'hooks/useApi'
import { Path } from '../commonConstants'
import {
  AddCaseStep,
  CASE_TITLE_MAX_LENGTH,
} from 'components/case/caseConstants'
import CategorySelector from 'components/category/CategorySelector'
import {
  Accordion,
  BackButtonWhite,
  MainHeader,
  SubHeader,
} from 'components/StyledComponents'
import { hasUnsavedChangesState } from 'state/formStates'
import { selectedLocationInfoState } from 'state/mapStates'
import { portalSettingState } from 'state/portalSettingStates'
import usePortalSetting from 'hooks/usePortalSetting'
import { convertCategoriesRawData } from 'utils/categoryUtils'
import { type CaseInfo } from 'components/case/caseTypes'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'

export type CaseEditFormData = {
  categoryIds: string
  title?: string
}

const CaseEditPage: React.FC = () => {
  const { goTo } = useRoute()
  const { formatMessage, locale } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const { caseId } = useParams()
  const [selectedCategoryName, setSelectedCategoryName] = useState<
    string | null
  >()
  const [currentStep, setCurrentStep] = useState<AddCaseStep | null>(
    AddCaseStep.ADDRESS,
  )
  const { sendPutRequest } = useApi()
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const selectedLocationInfo = useRecoilValue(selectedLocationInfoState)
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const { checkAccesses } = useMember()
  const {
    retrieveCategoryDataNames,
    extractLocalizedCategoryNames,
    extractCategoryIds,
  } = usePortalSetting()
  const { data: categoriesData, isLoading: isLoadingCategories } =
    useSWR<CategoriesRawData>(
      portalSetting
        ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/categories`
        : null,
    )
  const { data: caseInfoData, isLoading: isLoadingCase } = useSWR<CaseInfo>(
    portalSetting && caseId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/cases/${caseId}`
      : null,
  )
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
    getValues,
    watch,
  } = useForm<CaseEditFormData>({
    mode: 'onTouched',
  })

  const categoryIds = watch('categoryIds')

  useEffect(() => {
    if (caseInfoData) {
      setValue('title', caseInfoData.title)
    }

    if (caseInfoData?.category) {
      setValue('categoryIds', extractCategoryIds(caseInfoData.category))
      const labels = extractLocalizedCategoryNames(caseInfoData.category).join(
        ' > ',
      )
      setSelectedCategoryName(labels)
    }
  }, [caseInfoData])

  const hasUnsavedChanges = useMemo(
    () => !!selectedLocationInfo || isDirty,
    [selectedLocationInfo, isDirty],
  )

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  const handleStepChange =
    (newStep: AddCaseStep) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setCurrentStep(isExpanded ? newStep : null)
    }

  const onSubmit: SubmitHandler<CaseEditFormData> = useCallback(
    async (data): Promise<void> => {
      if (portalSetting && caseId) {
        try {
          setIsSaving(true)
          await sendPutRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting?.id
            }/cases/${caseId}`,
            {
              ...data,
              location: selectedLocationInfo,
              description: caseInfoData?.description,
              category: data.categoryIds,
            },
          )

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })

          goTo(`${Path.CASES_LIST}/${caseId}`, true)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [selectedLocationInfo, locale, caseId, caseInfoData],
  )

  const handleGoBack = (): void => {
    goTo(`${Path.CASES_LIST}/${caseId}`)
  }

  const categoryInputs = useMemo((): CategoryData[] => {
    if (!categoriesData) {
      return []
    }

    return convertCategoriesRawData(categoriesData)
  }, [categoriesData])

  const handleCategoryChange = useCallback(
    (categoryIds: string): void => {
      if (categoriesData) {
        setValue('categoryIds', categoryIds)
        setSelectedCategoryName(
          retrieveCategoryDataNames(categoryIds, categoryInputs).join(' > '),
        )
        setCurrentStep(AddCaseStep.CATEGORY)
      }
    },
    [categoriesData, locale],
  )

  if (isLoadingCategories && isLoadingCase) {
    return <CircularProgress />
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={0} sm={0} md={1} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={10} lg={6}>
          <Stack spacing={2} width={'100%'} padding={2}>
            <Stack direction={'row'} spacing={2}>
              <BackButtonWhite
                onClick={handleGoBack}
                size="small"
                aria-label={formatMessage({
                  id: 'general.icon_button.go_back',
                })}
              >
                <ArrowBackIcon />
              </BackButtonWhite>
              <MainHeader>
                {formatMessage({ id: 'case_edit.header' })}
              </MainHeader>
            </Stack>

            <Accordion
              defaultExpanded={true}
              expanded={currentStep === AddCaseStep.ADDRESS}
              onChange={handleStepChange(AddCaseStep.ADDRESS)}
              disableGutters
              elevation={0}
              data-testid="case-address-accordion"
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction={'row'} spacing={1} alignItems="center">
                  <CheckCircleIcon
                    color={
                      selectedLocationInfo?.address ? 'success' : 'disabled'
                    }
                  />

                  <SubHeader>
                    {selectedLocationInfo && currentStep !== AddCaseStep.ADDRESS
                      ? selectedLocationInfo.address
                      : formatMessage({
                          id: 'case_add.step.specify_case_location',
                        })}
                  </SubHeader>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingX: 6 }}>
                <Stack spacing={2}>
                  {portalSetting && (
                    <AddressInput
                      mapHeight={400}
                      selectedPosition={caseInfoData?.location?.position}
                      region={portalSetting.mapConfiguration?.region}
                      language={portalSetting.defaultLanguage.toLowerCase()}
                      zoom={portalSetting.mapConfiguration?.zoomLevel}
                    />
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion
              defaultExpanded={false}
              expanded={currentStep === AddCaseStep.CASE_INFO}
              onChange={handleStepChange(AddCaseStep.CASE_INFO)}
              disableGutters
              elevation={0}
              data-testid="case-info-accordion"
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction={'row'} spacing={1} alignItems="center">
                  <CheckCircleIcon
                    color={getValues('title') ? 'success' : 'disabled'}
                  />

                  <SubHeader>
                    {formatMessage({
                      id: 'case_add.step.case_information',
                    })}
                  </SubHeader>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingX: 6 }}>
                <Stack spacing={2}>
                  {checkAccesses({
                    [PortalSection.CASES]: [FeatureAccess.READ],
                  }) && (
                    <FormControl error={!!errors.title}>
                      <Controller
                        name="title"
                        control={control}
                        rules={{
                          maxLength: CASE_TITLE_MAX_LENGTH,
                        }}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors.title}
                            size="small"
                            label={formatMessage({
                              id: 'case_add.step.case_information.label.title',
                            })}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      {errors.title?.type === 'maxLength' && (
                        <FormHelperText>
                          {formatMessage(
                            {
                              id: 'general.error.max_length',
                            },
                            {
                              max: CASE_TITLE_MAX_LENGTH,
                            },
                          )}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>

            {!!categoriesData?.categoryInfo.categories?.length &&
              checkAccesses({
                [PortalSection.CASES]: [FeatureAccess.READ],
              }) && (
                <Accordion
                  defaultExpanded={false}
                  expanded={currentStep === AddCaseStep.CATEGORY}
                  onChange={handleStepChange(AddCaseStep.CATEGORY)}
                  disableGutters
                  elevation={0}
                  data-testid="case-category-accordion"
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack direction={'row'} spacing={1} alignItems="center">
                      <CheckCircleIcon
                        color={selectedCategoryName ? 'success' : 'disabled'}
                      />

                      <SubHeader>
                        {selectedCategoryName &&
                        currentStep !== AddCaseStep.CATEGORY
                          ? selectedCategoryName
                          : formatMessage({
                              id: 'case_add.step.select_category',
                            })}
                      </SubHeader>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingX: 6 }}>
                    <CategorySelector
                      selectedCategoryIds={categoryIds}
                      categories={categoryInputs}
                      isLoading={isLoadingCategories}
                      onCategoryChange={handleCategoryChange}
                    />
                  </AccordionDetails>
                </Accordion>
              )}

            <Box marginTop={2}>
              <LoadingButton
                variant="contained"
                type="submit"
                fullWidth
                disabled={
                  !isValid ||
                  Object.keys(errors).length > 0 ||
                  !selectedLocationInfo
                }
                loading={isSaving}
              >
                {formatMessage({ id: 'general.button.save' })}
              </LoadingButton>

              <Button variant="text" onClick={handleGoBack} fullWidth>
                {formatMessage({ id: 'general.button.cancel' })}
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={0} sm={0} md={1} lg={3}></Grid>
      </Grid>
    </form>
  )
}

export default CaseEditPage
