import { useCallback, useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'
import {
  type GridColDef,
  type GridEventListener,
  useGridApiRef,
} from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import {
  InfoText,
  DataTable,
  NotificationWrapper,
  SubSubHeader,
} from '../StyledComponents'
import { type Resource } from '../../types'
import {
  casesDataState,
  lastScrollPositionState,
  offsetState,
} from '../../state/caseListStates'
import { nameInitials, subWords } from '../../utils/stringUtils'
import ResourceWrapper from 'components/resource/ResourceWrapper'
import CaseStatusTag from 'components/case/CaseStatusTag'
import { AnonymousIcon } from 'components/icons/Icons'
import usePortalSetting from 'hooks/usePortalSetting'
import { getResourceFormat, getThumbnailUrl } from 'utils/fileUtils'
import CaseShareabilityIcon from 'components/case/CaseShareabilityIcon'
import { CaseFileSource } from 'components/case/caseConstants'
import EmptyImageIcon from 'assets/icons/empty_image.svg'
import { FileType } from 'commonConstants'
import CategoryTagList from 'components/category/CategoryTagList'
import { type CaseInfo } from 'components/case/caseTypes'

const PAGE_SIZE = 20

type CaseListProps = {
  isLoading: boolean
  onCaseClick?: (data: CaseInfo) => void
}

const ShortenTitleWrapper = styled(SubSubHeader)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
`

const CaseList: React.FC<CaseListProps> = ({ isLoading, onCaseClick }) => {
  const { formatMessage } = useIntl()
  const dataTableRef = useGridApiRef()
  const casesData = useRecoilValue(casesDataState)
  const { formatDate } = usePortalSetting()

  const columns: GridColDef[] = [
    {
      field: 'image',
      headerName: '',
      width: 100,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        const notificationElement = !params.row.commentsResolved ? (
          <NotificationWrapper position="absolute">!</NotificationWrapper>
        ) : null
        const { resources } = params.row
        const reportResources = resources?.filter(
          (resource: Resource) =>
            resource.source === CaseFileSource.REPORT_UPLOAD,
        )
        const resourcesElement = reportResources?.length ? (
          <ResourceWrapper
            size={70}
            url={params.row.resources[0].uri}
            format={getResourceFormat(params.row.resources[0])}
            imageSize="cover"
          />
        ) : (
          <ResourceWrapper
            size={70}
            format={FileType.IMAGE}
            imageSize="cover"
            hasBorder={false}
          >
            <EmptyImageIcon />
          </ResourceWrapper>
        )

        return (
          <>
            {notificationElement}
            {resourcesElement}
          </>
        )
      },
    },
    {
      field: 'addressAndTitle',
      headerName: formatMessage({ id: 'case_list.cases.header.address' }),
      flex: 3,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Stack>
          <ShortenTitleWrapper>
            {params.row.title || subWords(params.row.description, 15)}
          </ShortenTitleWrapper>
          <Stack direction="row">
            <InfoText>
              <LocationOnIcon sx={{ fontSize: 16 }} />
              {params.row.location.address}
            </InfoText>
          </Stack>
          <Box overflow="hidden">
            <CategoryTagList category={params.row.category} />
          </Box>
        </Stack>
      ),
    },
    {
      field: 'shareability',
      headerName: formatMessage({ id: 'case_list.cases.header.shareability' }),
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <CaseShareabilityIcon shareability={params.row.shareability} />
      ),
    },
    {
      field: 'status',
      headerName: formatMessage({ id: 'case_list.cases.header.status' }),
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => <CaseStatusTag status={params.value} />,
    },
    {
      field: 'createdBy',
      headerName: formatMessage({ id: 'case_list.cases.header.reported_by' }),
      flex: 2,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <CardHeader
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
          }}
          avatar={
            <Avatar
              src={getThumbnailUrl(params.row.reporter?.user?.avatarUrl)}
              sx={{ width: 30, height: 30, fontSize: 16 }}
              alt={
                params.row.reporter?.fullName ||
                params.row.reporter?.user?.fullName
              }
            >
              {params.row.anonymous ? (
                <AnonymousIcon />
              ) : (
                nameInitials(params.row.reporter?.fullName) ||
                nameInitials(params.row.reporter?.user?.fullName)
              )}
            </Avatar>
          }
          title={
            params.row.anonymous
              ? formatMessage({ id: 'case_detail.label.anonymous' })
              : params.row.reporter?.fullName?.trim() ||
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `${params.row.reporter?.user?.firstName} ${params.row.reporter?.user?.lastName}`
          }
          subheader={
            !params.row.anonymous && (
              <InfoText>
                {params.row.reporter?.email || params.row.reporter?.user?.email}
              </InfoText>
            )
          }
        />
      ),
    },
    {
      field: 'updated',
      headerName: formatMessage({ id: 'case_list.cases.header.updated_at' }),
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        const datetime = formatDate(params.value).split(' ')
        return (
          <Stack>
            <Typography variant="body1">{datetime[0]}</Typography>
            <InfoText>{datetime[1]}</InfoText>
          </Stack>
        )
      },
    },
  ]
  const setOffset = useSetRecoilState(offsetState)
  const [lastScrollPosition, setLastScrollPosition] = useRecoilState(
    lastScrollPositionState,
  )

  const lastLoadedMoreRowIndex = useRef(-1)

  const handleScrolling: GridEventListener<'scrollPositionChange'> =
    useCallback((params) => {
      if (dataTableRef.current) {
        const currentRowCount = dataTableRef.current.getRowsCount()
        const scrollPosition = params.renderContext?.lastRowIndex

        if (
          scrollPosition &&
          scrollPosition > 0 &&
          currentRowCount > 0 &&
          params.top > 0 &&
          scrollPosition >= currentRowCount - 1 &&
          currentRowCount > lastLoadedMoreRowIndex.current
        ) {
          console.log('load more data')
          lastLoadedMoreRowIndex.current = currentRowCount
          setOffset((prev) => prev + PAGE_SIZE)
        }
      }
    }, [])

  useEffect(() => {
    let subscriber: () => void
    if (dataTableRef.current) {
      subscriber = dataTableRef.current.subscribeEvent(
        'scrollPositionChange',
        handleScrolling,
      )
    }

    return () => {
      if (subscriber) {
        subscriber()
      }
    }
  }, [dataTableRef])

  useEffect(() => {
    if (lastScrollPosition && casesData.length && dataTableRef.current) {
      // hacky way to scroll until the scroll element loaded, can
      setTimeout(() => {
        dataTableRef.current.scroll(lastScrollPosition)
        setLastScrollPosition(null)
      }, 100)
    }
  }, [JSON.stringify(casesData)])

  const handleRowClick = useCallback((row: CaseInfo): void => {
    setLastScrollPosition(dataTableRef.current.getScrollPosition())
    if (onCaseClick) {
      onCaseClick(row)
    }
  }, [])

  return (
    <DataTable
      apiRef={dataTableRef}
      loading={isLoading}
      rows={casesData}
      columns={columns}
      sortModel={[
        {
          field: 'created',
          sort: 'desc',
        },
      ]}
      onRowClick={(param) => {
        handleRowClick(param.row)
      }}
      getRowHeight={() => 'auto'}
      slotProps={{
        row: {
          tabIndex: 0,
          onKeyDown: (event) => {
            if (event.key === 'Enter') {
              const row = event.target as HTMLTableRowElement
              row.click()
            }
          },
        },
      }}
      hideFooter
      hideFooterSelectedRowCount
      sx={{
        '& .MuiDataGrid-row': {
          position: 'relative',
        },
      }}
    />
  )
}

export default CaseList
