import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import {
  CASE_STATUS_LABEL,
  CaseStatus,
  type VirtualPortalCaseStatus,
} from './caseConstants'
import {
  getCaseStatusColor,
  getCaseStatusIcon,
} from 'components/case/caseUtils'

type CaseStatusTagProps = {
  status: CaseStatus | VirtualPortalCaseStatus
  size?: 'small' | 'medium' | 'large'
}

const Wrapper = styled(Stack)<{
  status: CaseStatus | VirtualPortalCaseStatus
  size: 'small' | 'medium' | 'large'
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ status }) => getCaseStatusColor(status)};

  color: ${({ status, theme }) =>
    status === CaseStatus.NEW
      ? theme.palette.common.white
      : theme.palette.common.black};
  border: none;

  & svg {
    color: ${({ status, theme }) =>
      status === CaseStatus.NEW
        ? theme.palette.common.white
        : theme.palette.common.black};
    font-size: ${({ size }) => {
      switch (size) {
        case 'small':
          return '14px'
        case 'large':
          return '24px'
        default:
          return '16px'
      }
    }};
  }
  align-items: center;
  border-radius: ${({ theme }) => 4 * theme.shape.borderRadius}px;
  font-weight: 500;
  font-size: ${({ size }) => {
    switch (size) {
      case 'small':
        return '11px'
      case 'large':
        return '14px'
      default:
        return '12px'
    }
  }};
  padding: ${({ size }) => {
    switch (size) {
      case 'small':
        return '4px 8px'
      case 'large':
        return '8px 16px'
      default:
        return '6px 12px'
    }
  }};
  min-width: ${({ size }) => {
    switch (size) {
      case 'small':
        return '90px'
      case 'large':
        return '130px'
      default:
        return '110px'
    }
  }};
`

const CaseStatusTag: React.FC<CaseStatusTagProps> = ({
  status,
  size = 'medium',
}) => {
  const { formatMessage } = useIntl()
  const CaseStatusIcon = getCaseStatusIcon(status)

  return (
    <Wrapper status={status} direction="row" spacing={1} size={size}>
      {CaseStatusIcon && <CaseStatusIcon />}
      <Box flexGrow={1}>
        {formatMessage({
          id: CASE_STATUS_LABEL[status],
        })}
      </Box>
    </Wrapper>
  )
}

export default CaseStatusTag
